/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./assets/font/Brandon_reg.otf') format('opentype');
}

@font-face {
  font-family: 'Brandon Grotesque Bold';
  src: url('./assets/font/Brandon_blk.otf') format('opentype');
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span {
  font-family: 'Brandon Grotesque';
}

.flashscreen {
  background: linear-gradient(180deg, #fcfcff 60.52%, #ffd100 101.81%);
  min-height: 100vh;
}

.center-all {
  padding: 0 5% 0 5%;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-icon-position {
  width: 100%;
  text-align: end;
}

.header-icon-size {
  width: 45%;
}

.header {
  text-align: center;
  background-color: #ffd100;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #001489;
  font-weight: 500;
}

.malaysia-container {
  padding: 5% 10%;
}

.malaysia {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.personal-info {
  background-color: #e95526;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 450;
  color: white;
  padding-left: 10%;
}

.form-container {
  padding: 5% 10%;
}

.hinttext {
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10%;
}

.red {
  color: red;
}

.question-tiitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 2%;
  padding-top: 2%;
}

.question-input {
  height: 50px;
  border-radius: 10px !important;
  border: 1px solid #a0a0a0 !important;
  margin-bottom: 5% !important;
}

.ok-button {
  background-color: #131685 !important;
  border-radius: 20px !important;
  color: white !important;
  width: 60%;
  height: 40px !important;
  font-size: 16px;
}

.ok-button-container {
  width: 100%;
  text-align: center;
}

.checkbox-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: left;
}

.checkboxsize {
  width: 95%;
  padding-bottom: 5%;
  gap: 5%;
}

.checkboxsize-special {
  width: 100%;
  padding-bottom: 5%;
  display: flex;
}

.special-checkbox {
  width: 50%;
}

.question-tiitle-two {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 2%;
  padding-top: 2%;
}

.ant-picker {
  width: 100%;
}

.ant-select {
  width: 100%;
  border-radius: 10px !important;
}

.ant-select-selector {
  width: 100%;
  height: 50px !important;
  border-radius: 10px !important;
  margin-bottom: 5% !important;
  display: flex;
  align-items: center !important;
}

.ant-select-arrow {
  margin-top: -1vh !important;
}

.ant-calendar-input-wrap {
  display: none;
}

.ant-checkbox-wrapper {
  padding-right: 5%;
  /* text-align: center; */
}

.errormessage {
  color: red;
  text-align: center;
}

.endpage {
  background: linear-gradient(180deg, #ffffff 59.03%, #ffd100 103.39%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.center-logo {
  text-align: center;
  padding-top: 20%;
}

.journey-header {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #131685;
}

.step-original {
  padding: 0 !important;
  display: block !important;
}

.modal-message {
  text-align: center;
}

.modal-inside {
  text-align: left;
}

.modal-image-container {
  text-align: end;
}

.modal-image {
  text-align: end;
  width: 70%;
}

.modal-header-css {
  color: #131685;
  font-size: 20px;
  font-weight: 500;
}

.modal-contain {
  margin-bottom: 5%;
}

.ok-button-two {
  background-color: #131685 !important;
  border-radius: 20px !important;
  color: white !important;
  width: 60%;
  height: 40px !important;
  font-size: 16px;
  text-align: center;
  margin-top: 5%;
}

.select-center {
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}

.yellowbg {
  background-color: #ffd100;
  width: 100%;
  padding: 5% 10%;
}

.first-font {
  font-size: 23px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.ok-button-three {
  background-color: #131685 !important;
  border-radius: 20px !important;
  color: white !important;
  width: 80%;
  height: 60px !important;
  font-size: 16px;
  text-align: center;
  margin-top: 2%;
  font-weight: 600 !important;
}

.select-please {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5%;

}

.header-upload {
  text-align: center;
  background-color: #ffd100;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001489;
  font-weight: 800;
  padding: 20% 15%;
  font-size: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.upload-input-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10%;
}

.upload-white-title {
  color: white;
  background-color: #e95526;
  padding-left: 10%;
  font-size: 18px;
  font-weight: 450;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.strechable {
  /* height: 70vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.upload-input-box {
  margin: 0 10% 0 10% !important;
  width: 80% !important;
  height: 40px !important;
  border-radius: 10px !important;
  border: 1px solid #a0a0a0 !important;
  margin-bottom: 5% !important;
}

.upload-container {
  border: 2px dashed #b5b4b4;
  padding: 5%;
  width: 80%;
  margin: 0 10%;
  border-radius: 20px;
  background: #ebebeb4a;
  margin-bottom: 5%;
}

.errormessage-new {
  color: red;
  text-align: center;
  padding: 0 5%;
  font-weight: 600;
}

.button-container {
  text-align: center;
  margin-bottom: 10%;
  padding-top: 5%;
}

.login-container-left {
  text-align: left;
  width: 80%;
}

.login-center {
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.login-button-container {
  width: 100%;
}

.upload-checkbox {
  padding-left: 10% !important;
}

.upload-input-title-checkbox {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.select-button-container {
  height: 25vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}