@media only screen and (min-width: 500px) {
    .ant-steps-item-container {
      padding-top: 5%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .ant-steps-item-container {
      padding-top: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .steps-force {
    display: flex !important;
    flex-direction: row !important;
  }
  
  .ant-steps-item-tail {
    width: 0px !important;
    height: 100%;
    padding: 100px 0 0px !important;
  }
  
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    width: 0px !important;
  }
  
  .ant-steps-item-title {
    color: #131685 !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
  }